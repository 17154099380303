<template>
  <div>
    <loader
      v-if="isLoading"
      message="Getting Users ...."
    />
    <div v-else>
      <v-container>
        <s-tabs v-model="selectedVerifiedTab">
          <v-tab
            v-for="filter in verifiedFilters"
            :key="filter.label"
            @click="updateHistory({name: 'users', query: { verified: filter.value }})"
          >
            {{ filter.label }}
            <v-chip
              v-if="filter.value !== 'all' && getFilteredCount('verified', filter.value )"
              small
              class="ml-2 px-2"
              style="height: 20px"
              color="primary"
              text-color="white"
            >
              {{ getFilteredCount('verified', filter.value ) }}
            </v-chip>
          </v-tab>
        </s-tabs>
        <v-row class="pa-0 ma-0 mt-3">
          <v-col class="" cols="1"></v-col>
          <v-col class="d-flex ml-n12 flex-column" cols="5">
            <s-text weight="medium" color="gray" size="sm">
              Username/Number
            </s-text>
          </v-col>
          <v-col class="d-flex flex-column" cols="3">
            <s-text weight="medium" color="gray" size="sm">
              Handle
            </s-text>
          </v-col>
          <v-col class="d-flex flex-column" cols="3">
            <s-text weight="medium" color="gray" size="sm">
              Date created
            </s-text>
          </v-col>
          <v-col class="action_group d-flex align-center"></v-col>
        </v-row>
        <v-row
          class="pa-0 ma-0 mb-3 app-card"
          v-for="user in filteredUsers"
          :key="user.id"
          @click="viewUser(user.user_id)"
        >
          <v-col class="" cols="1">
            <v-icon v-if="user.verified" color="green">mdi-check</v-icon>
          </v-col>
          <v-col class="d-flex ml-n12 flex-column" cols="5">
            <s-text
              weight="medium"
              color="grayTextAlt"
            >
              {{ isUsernameNumeric(user.username) }}{{ user.username }}
            </s-text>
          </v-col>
          <v-col class="d-flex flex-column" cols="3">
            <div>
              {{ user.handle }}
            </div>
          </v-col>
          <v-col class="d-flex flex-column" cols="3">
            <div>
              {{ user.created_at }}
            </div>
          </v-col>
          <v-col class="action_group d-flex align-center">
            <s-icon name="arrow-circle-right" />
          </v-col>
        </v-row>
      </v-container>
    </div>
    <s-dialog
      v-model="showModal"
      title="Create App"
      persistent
      isModal
    >
      <create-app
        v-if="showModal"
      />
    </s-dialog>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import Loader from '@/components/cards/Loader'
import CreateApp from '@/views/apps/CreateApp'
import { isUsernameNumeric } from '@/utils/fmt'

export default {
  name: 'Apps',
  data () {
    return {
      isLoading: false,
      showModal: this.$route.meta.showModal,
      filter: {},
      verifiedFilters: [
        {
          label: 'All',
          value: null,
        },
        {
          label: 'Verified',
          value: true,
        },
        {
          label: 'Unverified',
          value: false,
        },
      ],
    }
  },
  components: {
    loader: Loader,
    'create-app': CreateApp,
  },
  computed: {
    ...mapGetters({
      users: 'user/users',
    }),
    selectedVerifiedTab () {
      const filter = this.filter.verified
      if (filter === null) return 0

      return (String(filter) === 'true') ? 1 : 2
    },
    filteredUsers () {
      if (!this.users) return

      let filteredUsers = this.users

      for (const property in this.filter) {
        if (this.filter[property] === null) continue
        const filter = (property === 'verified')
          ? (String(this.filter[property]) === 'true')
          : this.filter[property]

        filteredUsers = filteredUsers.filter(user => user[property] === filter)
      }

      return filteredUsers
    },
  },
  methods: {
    async getApps () {
      this.isLoading = true
      await this.$store.dispatch('user/getUsers')
      this.isLoading = false
    },
    viewUser (id) {
      this.$router.push({ name: 'appDetails', params: { id: id } })
    },
    filterUsers (filter) {
      this.filter = { ...this.filter, ...filter }
    },
    getFilteredCount (key, value) {
      if (!this.users) return

      return this.users.filter(user => user[key] === value).length
    },
    updateHistory (route) {
      this.$router.push(route)
    },
    isUsernameNumeric,
  },
  watch: {
    '$route.meta' ({ showModal }) {
      this.showModal = showModal
    },
    '$route.query': {
      handler (data) {
        this.filterUsers(data)
      },
      immediate: true,
    },
  },
  mounted () {
    this.getApps()
  },
}
</script>

<style lang="scss">
@import "@/assets/styles/_variables.scss";
.count-box {
  background: #dfe2e6;
  border: 1px solid #dfe2e6;
  box-sizing: border-box;
  border-radius: 6px;
  width: 26px;
  height: 23px;
  color: var(--v-gray-base);;
  font-size: 14px;
  font-weight: 600;
}
.bg-white {
  background: white;
}
</style>
